@font-face {
  font-family: "Titre";
  src: url(./../public/fonts/Capture\ it.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Texte";
  src: url(./../public/fonts/ERASMD.TTF) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Texte";
  src: url(./../public/fonts/ERASDEMI.TTF) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Texte";
  src: url(./../public/fonts/ERASBD.TTF) format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: "Texte";
}

* h1 {
  font-family: "Titre";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #949494;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  box-sizing: border-box;
}

.full-page {
  width: 100vw;
  height: 100%;
}

.image600 {
  width: 100vh;
  object-fit: cover;
  position: relative;
  height: calc(100vh - 96px);
}

.view {
  position: relative;
}

.view h1 {
  padding-top: 4vh;
  font-weight: bold;
  color: #42424c;
}

.lastpage {
  height: calc(100vh - 100px);
}

.under-navbar {
  position: relative;
  top: 0px;
  height: calc(100vh - 100px);
}

.presentation {
  background: no-repeat 50% 50% url(./../public/assets/5169546.jpg);
  background-size: 110% 110%;
  height: 100vh;
  object-fit: contain;
}

.panorama {
  margin-top: 2vh;
  width: 96vw;
  height: 60vh;
  object-fit: cover;
  margin-left: 1vw;
  margin-right: 1vw;
}

.panorama-descr {
  margin-top: 3vh;
  font-size: 20px;
}

.footer1 {
  position: relative;
  left: 0;
  bottom: 0px;
  right: 0;
  height: 100px;
  border-top: 5px solid #2da537;
  background-color: #217a37;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer2 {
  position: absolute;
  left: 0;
  bottom: -4px;
  right: 0;
  height: 100px;
  border-top: 5px solid #2da537;
  background-color: #217a37;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-fixed {
  position: absolute;
  bottom: -4px;
}

.footer-container {
  position: relative;
  left: 7%;
  display: flex;
  height: 90px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-container2 {
  position: relative;
  right: 7%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-container2 a {
  color: #fff;
}

.footer-container .footer-title {
  position: relative;
  top: 15px;
}

.footer1 .footer-container .footer-title {
  position: relative;
  top: 25px;
}

.footer-container2 a:hover {
  color: rgb(209, 209, 209);
}

.footer-title h3 {
  color: white;
  font-weight: 600;
  position: relative;
}

.footer2 .footer-title {
  padding-top: 10px;
}

.footer1 .footer-title {
  padding-top: 0px;
}

.footer2 .links {
  padding-top: 15px;
  margin-left: 20px;
}

.footer1 .links {
  margin-top: 5px;
  margin-left: 20px;
}

.footer2 .links a {
  margin: 15px;
  margin-top: 25px;
}

.links {
  color: #fff;
}

.social-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -22px;
}


.social-buttons__button {
  margin: 10px 5px 0;
  padding: 5px;
}

.social-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 50px;
  height: 50px;
  text-decoration: none;
  border-radius: 100%;
  background: #fff;
  text-align: center;
  color: #217a37;
}
.social-button::after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: 0.3s;
  background: #217a37;
}
.social-button:focus,
.social-button:hover {
  color: #fff;
}
.social-button:focus::after,
.social-button:hover::after {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: calc(-50% - 1px);
}
.social-button svg {
  position: relative;
  z-index: 1;
  transition: 0.3s;
  height: 80%;
  width: 80%;
}

.pole {
  padding-top: 50px;
}

.pole h2 {
  font-weight: 600;
  color: #42424c;
}

.membres {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.membre {
  width: 22%;
  height: 400px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.headshot {
  flex-shrink: 0;
  margin: 20px;
  height: calc(150px + 6vw);
  width: calc(150px + 6vw);
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
}

.membre a {
  padding-top: 0px;
  color: #42424c;
}

.membre a:hover {
  color: #217a37;
}

.membre p {
  margin-top: 15px;
  font-size: 18px;
}
.headshot-1 {
  border-radius: 30%;
  background-image: linear-gradient(135deg, #2da52a, #217a37);
}

.headshot img {
  position: relative;
  margin-top: 5%;
  height: 90%;
  width: 90%;
  object-fit: cover;
  border-radius: 25%;
  object-position: top;
}

.membre-text {
  align-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.partenaire-container {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partenaire-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.partenaire-descr {
  padding-left: 7vw;
  color: #42424c;
  display: flex;
  width: 55vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.partenaire-descr h3 {
  color: #42424c;
  font-weight: 600;
}

.partenaire-descr h6 {
  color: #42424c;
  font-weight: 400;
  justify-content: flex-start;
}

.partenaire-image img {
  width: 250px;
  height: 120px;
  object-fit: contain;
}

.partenaire-url {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.partenaire-url a {
  color: #2da52a;
  font-weight: 600;
  padding-left: 10px;
}

.partenaire-url a h6 {
  color: #2da52a;
}

.partenaire-url a:hover {
  color: #217a37;
}

.partenaire-url a h6:hover {
  color: #217a37;
}

.devenir-container {
  position: relative;
  display: flex;
  left: 10%;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.devenir-container a {
  color: #42424c;
}

.devenir-container a:hover {
  color: #217a37;
}

.devenir-container h2 {
  font-weight: 600;
  color: #42424c;
  padding-top: 3vh;
}

.devenir-container h5 {
  padding-top: 2vh;
  color: #42424c;
}

.devenir-mot {
  color: #42424c;
  width: 70%;
  border: #217a37 solid 2px;
  padding: 0 20px 20px 20px;
  margin-bottom: 32px;
  border-radius: 50px 50px 50px 5px;
}

.devenir-mot h5 {
  color: #42424c;
  text-align: justify;
}

a.learn-more {
  width: 350px;
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  margin: 30px 0 30px 0;
}

a.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #217a37;
  border-radius: 1.625rem;
}
.alternative a.learn-more .circle {
  background: transparent;
}
a.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
a.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
a.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
a.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #217a37;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.alternative a.learn-more .button-text {
  color: #fff;
}

a:hover .circle {
  width: 100%;
}
a:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
a:hover .button-text {
  color: #fff;
}

.info-container {
  position: relative;
  left: 20%;
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.info-content h2 {
  font-weight: 600;
  color: #42424c;
}
.info-content-item-content {
  margin-bottom: 50px;
}

.info-content-item-content-item a {
  color: #42424c;
}

.info-content-item-content-item a:hover {
  color: #217a37;
}

.info-content-item-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.info-content-item-content-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.info-content-item-content-item h3 {
  font-weight: 500;
  margin-top: 20px;
  color: #42424c;
  width: 100%;
}

.info-content-item-content-item h5 {
  margin-top: 10px;
  color: #42424c;
  font-size: 18px;
  text-align: justify;
  width: 60%;
  margin-bottom: 5px;
}

.echeance {
  background: no-repeat 50% 50% url(./../public/assets/5169546.jpg);
  background-size: 100% 100%;
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: fill;
}

.echeance-container {
  position: relative;
  top: -3vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 10vw;
}

.timeline {
  position: relative;
  margin-top: 2vw;
  height: auto;
  background: rgba(255, 255, 255, 0.463);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  width: 70%;
  left: 15%;
  -webkit-backdrop-filter: blur(8px);
  align-items: center;
  justify-content: center;
}

.timeline-content {
  position: relative;
  top: 3vw;
  width: 100%;
}

.echeance-container h3 {
  color: #217a37;
  font-weight: 600;
  font-size: 1.9vw;
}

.echeance-date {
  font-weight: 600;
  color: #42424c;
  font-size: 1.4vw;
}

.inverted {
  position: relative;
  left: 0%;
}

.echeance-text {
  width: 100%;
  margin-top: 5px;
  color: #42424c;
  font-size: 1vw;
}

.formule {
  flex: 1;
  transition: 0.5s;
  transition-delay: 0.7s;
  width: 100vw;
  align-items: center;
  justify-content: center;
  height: 20vw;
  color: #ffffff;
  background: url(../public/assets/Raid/formule/images/sombre.png) center center
    no-repeat;
  background-blend-mode: normal;
  background-size: cover;
  border-radius: 40px;
  background-blend-mode: screen;
  transform: skew(-10deg);
}

.formule-item {
  transform: skew(10deg);
  height: 100%;
  justify-content: space-between;
}

.formule-content {
  margin-top: 0.8vw;
  transition: opacity 0.6s linear;
  opacity: 0;
  font-size: 1.1vw;
  width: 90%;
  position: relative;
  left: 5%;
  color: #ffffff;
  font-size: max(1.18vw, 1.2vh);
}

.formule-title {
  position: relative;
  transition: all 1s ease-out;
  top: 5vw;
  color: #ffffff;
}

.formule-title h1 {
  font-size: 35px;
  color: #ffffff;
  padding-top: calc(6vw - 50px);
}

.formule:hover {
  flex: 2;
  transition-delay: 0s;
}

.formule-item:hover > .formule-title {
  transform: translateY(0px);
  transition-delay: 0.5s;
  top: 0px;
}

.formule-item:hover > .formule-content {
  transition: opacity 1s linear;
  transition-delay: 0.5s;
  opacity: 1;
}

.formule-item:hover > .formule-data {
  transform: translateY(80px);
  transition-delay: 0.5s;
  opacity: 1;
}

.formule-data {
  position: relative;
  transition: all 1s ease-out;
  top: -80px;
  margin-top: 0.8vw;
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.km {
  margin-right: 2.2vw;
  align-items: center;
  justify-content: center;
}

.formule-data span {
  font-weight: 600;
  margin-left: 0.8vw;
  font-size: 1.2vw;
}

.formule-icon {
  position: relative;
  height: 2vw;
  top: calc(1vw - 10px);
}

.inscr-container h5 {
  margin-top: 2vw;
}

.inscr-container a {
  color: #42424c;
}

.inscr-container a:hover {
  color: #217a37;
}

.inscr-content {
  width: 70vw;
  position: relative;
  left: 15vw;
}

.sponsors-container {
  position: relative;
  width: 100%;
  top: 20vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
}

.static {
  position: relative;
  top: calc(40% - 120px);
}

.sponsors-image {
  height: 240px;
  width: 500px;
  margin-right: 50px;
  object-fit: contain;
}

.navbar-toggler {
  margin-right: 15px;
}

.galerie-container {
  margin: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
}

@media only screen and (min-width: 1024px) {
  .pres {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  @keyframes an {
    from {
      opacity: 0;
      transform: perspective(500px) translate3d(-35px, -40px, -150px)
        rotate3d(1, -1, 0, 35deg);
    }
    to {
      opacity: 1;
      transform: perspective(500px) translate3d(0, 0, 0);
    }
  }

  .logo-app {
    margin: 0;
  }

  .brand {
    padding-right: 3vw;
  }

  .brand h2 {
    font-size: 2vw;
  }

  .test {
    margin-right: 2vw;
  }

  .test2 {
    font-weight: 600;
  }

  .video {
    top: 15vh;
    position: relative;
  }

  .descr {
    position: relative;
    top: 15vh;
    height: calc(43vw * 450 / 800);
    width: 43vw;
    background: rgba(255, 255, 255, 0.556);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .descr h2 {
    font-weight: bold;
    color: #42424c;
    font-size: 2vw;
  }

  .descr h5 {
    position: relative;
    color: #42424c;
    width: 80%;
    font-size: 1.2vw;
    padding-bottom: 3vh;
  }

  .video iframe, .video-parcours iframe {
    height: calc(43vw * 450 / 800);
    width: 43vw;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }

  .video span {
    padding-top: 20px;
    font-size: 20px;
  }

  .formule-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .formule {
    width: 30vw;
    margin: 50px;
    margin-top: 10%;
    align-items: center;
    justify-content: center;
    color: #42424c;
  }

  .echeance-text {
    width: 80%;
  }

  .pole {
    padding-top: 50px;
  }

  .pole h2 {
    font-weight: 600;
    color: #42424c;
  }

  .membres {
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .membre {
    width: 20%;
    height: 400px;
    align-items: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .headshot {
    flex-shrink: 0;
    margin: 20px;
    height: calc(150px + 6vw);
    width: calc(150px + 6vw);
    border: calc(8px + 0.2vw) solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-size: cover;
    box-sizing: border-box;
  }

  .membre a {
    padding-top: 0px;
    color: #42424c;
  }

  .membre a:hover {
    color: #217a37;
  }

  .membre p {
    margin-top: 15px;
    font-size: 18px;
  }
  .headshot-1 {
    border-radius: 30%;
    background-image: linear-gradient(135deg, #2da52a, #217a37);
  }

  .headshot img {
    position: relative;
    margin-top: 5%;
    height: 90%;
    width: 90%;
    object-fit: cover;
    border-radius: 25%;
  }

  .membre-text {
    align-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .form {
    height: calc(100vh - 19px);
  }
}

@media only screen and (max-width: 1023px) {
  .brand h2 {
    font-size: 18px;
  }

  .pres {
    display: flex;
    height: calc(100vh - 100px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .video {
    position: relative;
    top: 3vh;
    left: max(calc(50% - 30vw), calc(50% - (20vh * 800 / 450)));
    margin-bottom: 3vh;
  }

  .video iframe {
    height: min(calc(60vw * 450 / 800), 40vh);
    width: min(60vw, calc(40vh * 800 / 450));
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }

  .descr {
    position: relative;
    left: calc(50% - 40vw);
    height: auto;
    width: 80vw;
    background: rgba(255, 255, 255, 0.668);
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.3px);
    -webkit-backdrop-filter: blur(9.3px);
  }

  .descr h2 {
    font-weight: bold;
    color: #42424c;
    margin-top: 18px;
    font-size: 22x;
  }

  .descr h5 {
    position: relative;
    left: 5%;
    color: #42424c;
    margin-top: 15px;
    width: 90%;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .static {
    position: relative;
    top: calc(40% - 60px);
  }

  .sponsors-image {
    height: 120px;
    width: 250px;
    margin-right: 25px;
  }

  .formule-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 85%;
    margin-top: 2vw;
  }

  .formule {
    width: 70vw;
    justify-content: center;
    margin: 1vw;
    margin-bottom: 5vh;
  }

  .formule-content {
    font-size: max(1.5vw, 1.9vh);
  }

  .formule-title {
    top: max(2vw, 4vh);
  }

  .formule-title h1 {
    font-size: min(35px, 4vh);
    color: #ffffff;
    margin-top: calc(2vh - 50 * (100vw - 1023px) / 1023);
  }

  .formule-data span {
    font-size: max(1.5vw, 2.2vh);
  }

  .formule-icon {
    position: relative;
    height: max(2vw, 3vh);
    top: calc(max(2vw, 3vh) / 2 - 10px);
  }

  .timeline {
    width: 80%;
    left: 10%;
    height: 80%;
  }

  .timeline-content {
    position: relative;
    top: max(2vw, 8vh);
    width: 100%;
  }

  .echeance-item {
    height: max(23vh, 15vw);
  }

  .echeance-text {
    width: 100%;
    font-size: 1.3vw;
  }

  .echeance-container h3 {
    font-size: 2.1vw;
  }

  .echeance-date {
    font-size: 1.6vw;
  }

  .info-container {
    position: relative;
    left: 10%;
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .membres {
    flex-direction: column;
  }

  .membre {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1030px) {
  .footer1,
  .footer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-container {
    position: relative;
    left: 0%;
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footer-container2 {
    position: relative;
    right: 0%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footer-container2 a {
    color: #fff;
  }

  .footer-container .footer-title {
    position: relative;
    top: 5px;
  }

  .footer1 .footer-container .footer-title {
    position: relative;
    top: 0px;
  }

  .footer-container2 a:hover {
    color: rgb(209, 209, 209);
  }

  .footer-title h3 {
    color: white;
    font-weight: 600;
    position: relative;
  }

  .footer2 .footer-title {
    padding-top: 10px;
  }

  .footer1 .footer-title {
    padding-top: 10px;
  }

  .footer2 .links {
    padding-top: -10px;
    margin-left: 20px;
  }

  .footer1 .links {
    margin-top: -20px;
    margin-left: 20px;
  }

  .footer2 .links a {
    margin-left: 0;
    margin-top: 15px;
  }

  .footer1 .links a {
    margin-left: 0;
    margin-top: 25px;
  }

  .footer2 .footer-container2 .links {
    margin-top: 00px;
  }

  .footer1 .footer-container2 .links {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .brand {
    padding-right: 3vw;
  }

  .brand h2 {
    font-size: 4vw;
  }

  .footer1,
  .footer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-container {
    position: relative;
    left: 0%;
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footer-container2 {
    position: relative;
    right: 0%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footer-container2 a {
    color: #fff;
  }

  .footer-container .footer-title {
    position: relative;
    top: 5px;
  }

  .footer1 .footer-container .footer-title {
    position: relative;
    top: 5px;
  }

  .footer-container2 a:hover {
    color: rgb(209, 209, 209);
  }

  .footer-title h3 {
    color: white;
    font-weight: 600;
    font-size: 18px;
    position: relative;
  }

  .footer2 .footer-title {
    padding-top: 10px;
  }

  .footer1 .footer-title {
    padding-top: 10px;
  }

  .footer2 .links {
    padding-top: 0px;
    margin-left: 20px;
    font-size: 12px;
  }

  .footer1 .links {
    margin-top: -20px;
    margin-left: 20px;
    font-size: 12px;
  }

  .footer2 .links a {
    margin-left: 0;
    margin-top: 30px;
  }

  .footer1 .links a {
    margin-left: 0;
    margin-top: 30px;
  }

  .footer2 .footer-container2 .links {
    margin-top: 10px;
  }

  .footer1 .footer-container2 .links {
    margin-top: 10px;
  }

  .social-button {
    width: 40px;
    height: 40px;
  }

  .social-button svg {
    height: 25px;
  }

  .presentation {
    background-size: 180% 100%;
  }

  .formule {
    width: 80vw;
    justify-content: center;
    margin: 1vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .formule-content {
    padding-left: 10px;
    font-size: max(1.5vw, 1.9vh);
  }

  .formule-title {
    top: max(8vw, 5vh);
  }

  .formule-title h1 {
    font-size: min(20px, 3vh);
    color: #ffffff;
    margin-top: calc(3vh - 50 * (100vw - 700px) / 700);
  }

  .formule-data span {
    font-size: max(1.5vw, 2.2vh);
  }

  .formule-icon {
    position: relative;
    height: max(2vw, 3vh);
    top: calc(max(2vw, 3vh) / 2 - 10px);
  }

  .timeline {
    width: 90%;
    left: 5%;
    height: 80%;
  }

  .timeline-content {
    position: relative;
    top: 80px;
    width: 100%;
  }

  .echeance {
    background-size: 200% 100%;
  }

  .echeance-item {
    height: 23vh;
  }

  .echeance-text {
    width: 100%;
    font-size: 9px;
  }

  .echeance-container h3 {
    font-size: 15px;
  }

  .echeance-date {
    font-size: 11px;
  }

  .partenaire-container {
    padding-top: max(30px, 5vw);
  }

  .partenaire-image {
    padding-bottom: max(30px, 5vw);
  }

  .partenaire-descr {
    padding-left: 1vw;
    width: 55%;
  }

  .partenaire-descr h6 {
    font-size: max(2vw, 1.5vh);
  }

  .partenaire-image img {
    width: 37vw;
    height: calc(12 / 25 * 37vw);
    object-fit: contain;
  }

  .partenaire-url a {
    color: #2da52a;
    font-weight: 600;
    padding-left: 10px;
  }

  .devenir-container {
    width: 90%;
    left: 5%;
  }

  .devenir-mot {
    width: 90%;
  }

  .info-container {
    left: 5%;
    width: 90%;
  }

  .info-content-item-content-item h5 {
    width: 80%;
  }
}

.register {
  font-size: 25px;
}